import React from 'react'
import styles from './ModalCmp.module.scss'
import classNames from 'classnames'
import { PerformanceCriteriaList } from '../'

const ModalCmp = ({
  cardTitle,
  textSection,
  leidarljos,
  accordionItems
}) => {
  return (
    <div className={classNames(
      styles.modalContainer, 'pb-4 row d-flex flex-column align-items-center')}
    >
      <div className='col-md-6 pt-3 pt-md-4'>
        <h2 className={styles.heading}>{cardTitle}</h2>
        <h3 className='hdln--small'>Leiðarljós</h3>
        <p className={styles.subHeading}>{leidarljos}</p>
      </div>
      <div className='col-md-6 pt-2'>
        <PerformanceCriteriaList
          accordionItems={accordionItems.accordionItems}
          heading={accordionItems.heading}
        />
        {textSection &&
          <div dangerouslySetInnerHTML={{ __html: textSection }} />
        }
      </div>
    </div>
  )
}

export default ModalCmp
