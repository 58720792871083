import React from 'react'
import styles from './Graph.module.scss'
import { get } from 'lodash'

const Graph = data => {
  const description = get(data, 'primary.description.text', '')
  const graph = get(data, 'primary.image.url', '')

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {graph &&
          <img className={styles.image} alt={description} src={graph} />
        }
        <div className={styles.description}>
          {description}
        </div>
      </div>
    </div>
  )
}

export default Graph
