import React from 'react'
import styles from './Footer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInvision, faYoutube, faVimeo } from '@fortawesome/free-brands-svg-icons'
import { get } from 'lodash'
import { Link } from '../'
import classNames from 'classnames'

const Footer = ({ data }) => {
  const footerData = get(data, 'allPrismicOptions.edges[0].node.data')
  const facebookUrl = get(footerData, 'facebook.url')
  const twitterUrl = get(footerData, 'twitter.url')
  const linkedInUrl = get(footerData, 'linkedin.url')
  const youtubeUrl = get(footerData, 'youtube.url')
  const vimeoUrl = get(footerData, 'vimeo.url')

  const containerClasses = classNames('mt-3', styles.container)
  const innerContainerClasses = classNames('d-md-flex', 'justify-content-between', 'align-items-center', styles.innerContainer)
  const thirdColClasses = classNames(styles.thirdCol, styles.thirdWidth)

  return (
    <div className={containerClasses}>
      <div className={innerContainerClasses}>
        <div className={styles.thirdWidth}>
          <h3 className='pt-1 pb-1'>Íslandsstofa</h3>
        </div>
        <div className={styles.socialLinks}>
          {facebookUrl &&
            <Link to={facebookUrl}>
              <FontAwesomeIcon
                size='2x'
                icon={faFacebook} />
            </Link>
          }
          {twitterUrl &&
            <Link to={twitterUrl}>
              <FontAwesomeIcon
                size='2x'
                icon={faTwitter} />
            </Link>
          }
          {linkedInUrl &&
            <Link to={linkedInUrl}>
              <FontAwesomeIcon
                size='2x'
                icon={faInvision} />
            </Link>
          }
          {youtubeUrl &&
            <Link to={youtubeUrl}>
              <FontAwesomeIcon
                size='2x'
                icon={faYoutube} />
            </Link>
          }
          {vimeoUrl &&
            <Link to={vimeoUrl}>
              <FontAwesomeIcon
                size='2x'
                icon={faVimeo} />
            </Link>
          }
        </div>
        <div className={thirdColClasses}>
          <p className='pt-1 pb-1'>Stefnumótun 2019</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
