import React from 'react'
import className from 'classnames'
import PropTypes from 'prop-types'
import Button from '../Button'
import styles from './Hero.module.scss'
import Image from 'gatsby-image'
import { get } from 'lodash'
import { getDocumentUrl } from '../../misc/utilities'
import { Link } from '../'
import { hyphenate } from '../../utils/hypher.js'
import { ConsumerWrapper } from '../../Context'

const Hero = ({
  index,
  primary,
  consumerContext: {
    toggleMenuTo
  }
}) => {
  const {
    hero_title,
    hero_short_title,
    hero_text,
    hero_button_text,
    hero_image,
    hero_link
  } = primary

  const flipped = index % 2 === 1

  const heroClasses = className(
    'hero',
    styles.hero,
    {
      [styles.heroFlipped]: flipped
    }
  )

  const firstColClasses = className(
    `col-lg-6`,
    `order-lg-${flipped ? 2 : 1}`
  )

  const secondColClasses = className(
    `col-lg-6`,
    `order-lg-${flipped ? 1 : 2}`
  )

  const heroContent = className(
    styles.heroContent
  )

  const fluidImage = get(hero_image, 'localFile.childImageSharp.fluid')

  const isTopLevelLink = get(hero_link, 'document[0].__typename', '') === 'PrismicTopLevelPage'
  const menuLabel = get(hero_link, 'document[0].data.title.text', '')
  const linkUrl = getDocumentUrl(hero_link)

  return (
    <section className='section'>
      <div className={heroClasses}>
        <div className='container' >
          <div className='row'>
            <div className={firstColClasses}>
              <div className={heroContent}>
                <h2 className='hdln--mini'>{hero_short_title.text}</h2>
                <h3 className={className('hdln--2', styles.title)}>{hyphenate(hero_title.text)}</h3>
                <p className='pb-2'>{hero_text.text}</p>
                {linkUrl &&
                  <div className='pt-2'>
                    {!isTopLevelLink &&
                      <Link to={linkUrl}>
                        <Button>{hero_button_text.text}</Button>
                      </Link>
                    }
                    {isTopLevelLink &&
                      <Button onClick={e => {
                        toggleMenuTo(menuLabel, e)
                      }}>{hero_button_text.text}</Button>
                    }
                  </div>
                }
              </div>
            </div>
            <div className={secondColClasses}>
              {fluidImage &&
                <div className={styles.imageContainer}>
                  <Image
                    fluid={fluidImage}
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const defaultPropType = PropTypes.exact({
  text: PropTypes.string,
  html: PropTypes.string
})

Hero.propTypes = {
  hero_short_title: defaultPropType,
  hero_title: defaultPropType,
  hero_text: defaultPropType,
  hero_button_text: defaultPropType,
  index: PropTypes.number
}

const defaultProp = (text = '', html = '') => {
  return {
    text,
    html
  }
}

Hero.defaultProps = {
  index: 0,
  hero_short_title: defaultProp('Short title here'),
  hero_title: defaultProp('And a longer title here'),
  hero_button_text: defaultProp('Skoða nánar'),
  hero_text: defaultProp('Þessi skýrsla er yfirlit yfir þau verkefni sem unnin voru á árinu 2017 á vegum Íslandsstofu og gefur hún góða sýn yfir það víðfeðma starf sem unnið er á okkar vegum. Skýrslunni er skipt í kafla eftir sviðum í samræmi við skipurit stofunnar.')
}

export default ConsumerWrapper(Hero, ['toggleMenuTo'])
