import React, { useState, useEffect } from 'react'
import styles from './PerformanceCriteriaCard.module.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltRight } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import Modal from 'react-modal'
import { ModalCmp } from '..'

const PerformanceCriteriaCard = ({
  text,
  cardTitle,
  theme,
  modalData,
  textSection,
  accordionItems,
  leidarljos,
  icon
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const cardTheme = classNames(
    'my-1',
    styles.card,
    `card--${theme}`
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', () => {
        setIsOpen(false)
      })
    }
  })
  return (
    <div className={cardTheme}>
      <button onClick={toggleModal}>
        <div className={classNames(styles.iconSection, 'icon')}>
          <img src={icon} alt=' ' />
        </div>
        <div className={styles.infoSection}>
          <h2>{cardTitle}</h2>
          <p>{text}</p>
        </div>
        <div className={styles.seeMore}>
          <span>
            Sjá nánar
            <FontAwesomeIcon icon={faArrowAltRight} />
          </span>
        </div>
      </button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel='Árangursviðmið'
        shouldCloseOnOverlayClick
        className={classNames(styles.modal, 'container')}
        overlayClassName={styles.overlay}
      >
        <div className='row'>
          <span className={styles.closeBtn}>
            <span>Loka glugga</span>
            <button
              onClick={toggleModal}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </span>
        </div>
        <ModalCmp
          leidarljos={leidarljos}
          cardTitle={cardTitle}
          textSection={textSection}
          accordionItems={accordionItems}
        />
      </Modal>
    </div>
  )
}

export default PerformanceCriteriaCard
