import React, { useState, Fragment } from 'react'
import styles from './PerformanceCriteriaList.module.scss'
import classNames from 'classnames'

const PerformanceCriteriaList = ({ accordionItems, heading, title, text }) => {
  const [openItem, setOpenItem] = useState({})
  const toggleAccordionItems = item => {
    openItem === item ? setOpenItem(null) : setOpenItem(item)
  }

  return (
    <Fragment>
      {title &&
        <h2 className={styles.title}>{title}</h2>
      }
      {text &&
        <p>{text}</p>
      }
      <h2 className='pb-1 text-uppercase'>{heading}</h2>
      <div className={styles.accordion}>
        {accordionItems &&
          Object.values(accordionItems).map((item, key) => {
            const {
              bottom_text: { html: bottomText },
              titill: { html: titill },
              texti: { text: texti }
            } = item.aherslu_rod.document[0].data

            return (
              <div
                key={key}
                className={styles.rowWrapper}
              >
                <div
                  onClick={() => toggleAccordionItems(item)}
                  className={classNames(
                    styles.accordionRow,
                    { [styles.open]: item === openItem }
                  )}
                >
                  <div
                    className={classNames(
                      'd-flex justify-content-between',
                      styles.topHeading,
                      { [styles.topHeadingOpen]: item === openItem })}
                  >
                    <div dangerouslySetInnerHTML={{ __html: titill }} />
                    <div
                      className={classNames(
                        styles.arrowBtn,
                        { [styles.openBtn]: item === openItem }
                      )}
                    />
                  </div>
                  <div className={styles.topSubheading}>
                    {texti}
                  </div>
                </div>
                <div
                  className={classNames(
                    styles.bottom,
                    { [styles.openBottom]: item === openItem }
                  )}
                >
                  <div className={styles.accordionRowBottom} dangerouslySetInnerHTML={{ __html: bottomText }} />
                </div>
              </div>
            )
          })}
      </div>
    </Fragment>
  )
}

export default PerformanceCriteriaList
