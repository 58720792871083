import React from 'react'
import styles from './Video.module.scss'
import ReactPlayer from 'react-player'
import { get } from 'lodash'

const Video = (data) => {
  const videoUrl = get(data, 'primary.video.embed_url', '')

  return (
    <div className='container'>
      {videoUrl &&
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-10 pb-3'>
            <div className={styles.videoWrapper}>
              <ReactPlayer url={videoUrl} width='100%' height='auto' />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Video
