import React, { useState, Fragment } from 'react'
import styles from './TopHeroVideo.module.scss'
import ReactPlayer from 'react-player'
import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-modal'
import { Button } from '../'

const TopHeroVideo = ({ videoUrl, buttonText }) => {
  const [show, setShow] = useState(false)

  return (
    <Fragment>
      {buttonText &&
        <div className='text-center pt-1'>
          <Button
            white
            icon={<FontAwesomeIcon icon={faPlay} />}
            onClick={() => setShow(true)}
          >
            {buttonText}
          </Button>
        </div>
      }

      <Modal
        isOpen={show}
        onRequestClose={() => setShow(false)}
        contentLabel='Myndband'
        shouldCloseOnOverlayClick
        className={styles.modal}
        overlayClassName={styles.overlay}
        style={{
          content: {
            WebkitOverflowScrolling: 'touch'
          }
        }}
      >
        <ReactPlayer
          url={videoUrl.embed_url}
          width={728}
          height={410}
          style={{
            margin: '0 auto',
            maxWidth: '100%',
            height: 'auto'
          }}
          playing
          controls
        />
      </Modal>

    </Fragment>
  )
}

export default TopHeroVideo
