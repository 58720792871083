import Hypher from 'hypher'
import { icelandic } from './patterns.js'

const config = {
  ...icelandic,
  leftmin: 3,
  rightmin: 4
}

const h = new Hypher(config)

export const hyphenate = string => {
  if (string && string.indexOf('&shy;') === -1) {
    const output = string.split(' ').map(v => {
      return h.hyphenate(v).join('\u00AD')
    })

    return output.join(' ')
  }

  return string
}
