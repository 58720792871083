import React from 'react'
import styles from './FullImage.module.scss'
import { get } from 'lodash'

const FullImage = data => {
  const description = get(data, 'primary.description.text', '')
  const {
    url,
    alt
  } = data.primary.image

  return (
    <div className={styles.container}>
      {url &&
        <img
          alt={alt}
          className={styles.image}
          src={url} />
      }
      <div className={styles.description}>
        {description}
      </div>
    </div>
  )
}

export default FullImage
