import React from 'react'
import styles from './PerformanceCriteriaWapper.module.scss'
import Card from '../PerformanceCriteriaCard'
import classNames from 'classnames'

const PerformanceCriteriaWapper = data => {
  const {
    items
  } = data

  const {
    title1: { text: title }
  } = data.primary

  return (
    <section className='section' id='performanceCards'>
      <div className='container'>
        <div className='row'>
          <div className='col offset-md-3'>
            <h2 className={styles.heading}>{title}</h2>
          </div>
        </div>
        <div className='row d-flex flex-wrap'>
          <div className={classNames(styles.cardContainer, 'col-12')}>
            {Object.values(items).map((card, i) => {
              const {
                flokkur,
                leidarljos: { text: leidarljos },
                vidmidalisti,
                titill: { text: titill },
                content_section: { html: textSection },
                icon: { url: icon }
              } = card.vidmida_spjold.document[0].data

              const accordionItems = {
                accordionItems: vidmidalisti.document[0].data.aherslulisti,
                heading: vidmidalisti.document[0].data.titill.text
              }

              return <Card
                leidarljos={leidarljos}
                textSection={textSection}
                accordionItems={accordionItems}
                modalData={card.modalData}
                icon={icon}
                text={leidarljos}
                cardTitle={titill}
                theme={flokkur}
                key={i}
              />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PerformanceCriteriaWapper
