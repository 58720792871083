import React, { Component } from 'react'
import className from 'classnames'
import styles from './TopHero.module.scss'
import PropTypes from 'prop-types'
import { Button, Link, TopHeroVideo } from '../'
import { get } from 'lodash'
import { ConsumerWrapper } from '../../Context'
import { getDocumentUrl } from '../../misc/utilities'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import { hyphenate } from '../../utils/hypher.js'
import chartBar from '../../images/icons/chart-bar.png'
import smoothscroll from 'smoothscroll-polyfill'

class TopHero extends Component {
  constructor (props) {
    super(props)

    this.state = {
      doTween: true
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateTween.bind(this))
    this.updateTween()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateTween.bind(this))
  }

  updateTween () {
    this.setState({
      doTween: window.innerWidth > 500
    })
  }

  render () {
    if (typeof window !== 'undefined') {
      smoothscroll.polyfill()
    }
    const {
      consumerContext: {
        showMenu
      },
      primary
    } = this.props

    const {
      text1,
      text2,
      button_text: {
        text: buttonText
      },
      link,
      background,
      addOverlay,
      heroVideoLink,
      skrunTakki
    } = primary

    const {
      doTween
    } = this.state

    const imageData = get(background, 'localFile.childImageSharp.fluid.src')
    const buttonLink = get(link, 'url')
    const linkUrl = getDocumentUrl(link)

    const textGet = (obj, path, defaultValue) => {
      const v = get(obj, path, defaultValue)
      return v === null ? defaultValue : v
    }

    const scrollDown = () => {
      if (typeof window !== 'undefined') {
        const cardId = document.querySelector('#performanceCards')
        cardId.scrollIntoView({ behavior: 'smooth' })
      }
    }

    const t2 = textGet(text2, 'text', '')
    const t1 = textGet(text1, 'text', '')

    const content = (
      <div
        className={className(styles.container, { [styles.menuOpen]: showMenu })}>
        {imageData &&
          <div
            className={styles.backgroundContainer}
            style={{ backgroundImage: `url(${imageData})` }}
          />
        }
        {addOverlay === 'Já' &&
          <div className={styles.overlay} />
        }
        <div className={styles.content}>
          <h1 className={styles.text}>
            {t1 &&
              <span className={styles.text1}
                dangerouslySetInnerHTML={{
                  __html: hyphenate(t1)
                }}
              />
            }
            {t2 &&
              <span className={styles.text2}
                dangerouslySetInnerHTML={{
                  __html: hyphenate(t2)
                }}
              />
            }
          </h1>
          {linkUrl && buttonText && !heroVideoLink &&
            <div className='text-center pt-1'>
              <Link to={buttonLink}>
                <Button white>
                  {buttonText}
                </Button>
              </Link>
            </div>
          }

          {heroVideoLink && (
            <TopHeroVideo
              videoUrl={heroVideoLink}
              buttonText={buttonText}
            />
          )}
        </div>
        {skrunTakki !== null && (
          <button
            onClick={scrollDown}
            className={styles.chartIconContainer}
          >
            <div className={styles.chartIcon}>
              <img src={chartBar} alt=' ' />
            </div>
            <p className='pl-1 parag parag--small parag--white parag--uppercase'>{skrunTakki}</p>
          </button>
        )}
      </div>
    )

    if (doTween) {
      return (
        <Controller>
          <Scene
            triggerElement='.content-wrapper'
            triggerHook={1}
            duration={'100%'}
          >
            {progress => {
              return (
                <Tween
                  to={{
                    opacity: 0.66,
                    top: -100
                  }}
                  totalProgress={progress}
                  paused
                >
                  {content}
                </Tween>
              )
            }}
          </Scene>
        </Controller>
      )
    }

    return content
  }
}

const defaultPropType = PropTypes.exact({
  text: PropTypes.string,
  html: PropTypes.string
})

TopHero.propTypes = {
  text1: defaultPropType,
  text2: defaultPropType,
  button_text: defaultPropType
}

const defaultProp = (text = '', html = '') => {
  return {
    text,
    html
  }
}

TopHero.defaultProps = {
  text1: defaultProp(''),
  text2: defaultProp(''),
  button_text: defaultProp('Sjá nánar')
}

export default ConsumerWrapper(TopHero, ['set', 'showMenu'])
