import React from 'react'
import { PerformanceCriteriaList } from '../'

const PerformanceCriteriaListWrapper = data => {
  const {
    texti,
    titill
  } = data.primary

  return (
    <div className='container'>
      <div className='row d-flex justify-content-center'>
        <div className='col-md-6 pt-2'>
          <PerformanceCriteriaList
            accordionItems={data.primary.aherslulisti.document[0].data.aherslulisti}
            heading={data.primary.aherslulisti.document[0].data.titill.text}
            title={titill.text}
            text={texti.text}
          />
        </div>
      </div>
    </div>
  )
}

export default PerformanceCriteriaListWrapper
