import React from 'react'
import styles from './TextWithImage.module.scss'
import { get } from 'lodash'
import classNames from 'classnames'
import { getDocumentUrl } from '../../misc/utilities'
import { Link, Button } from '../'

const TextWithImage = data => {
  const {
    url,
    alt
  } = data.primary.mynd
  const text = get(data, 'primary.texti.html', '')
  const myndatexti = get(data, 'primary.myndatexti.text', null)
  const myndatextiWithMarkup = myndatexti !== null ? `<p>${myndatexti}</p>` : ''
  const wrapperClasses = classNames('image-right', {
    'image-right--w-caption': myndatextiWithMarkup != null
  })
  // TODO: this seems sketchy
  const textWImage = `<div class="${wrapperClasses}"><img alt="${alt}" src="${url}">${myndatextiWithMarkup}</div>${text}`
  const linkUrlObj = get(data, 'primary.link_url', '')
  const linkUrl = getDocumentUrl(linkUrlObj)
  const linkText = get(data, 'primary.link_name.text', '')

  return (
    <div className={classNames('content-section', styles.container)}>
      <div className={styles.innerContainer} dangerouslySetInnerHTML={{ __html: textWImage }} />
      {linkUrl && linkText &&
        <div className='pt-2'>
          <Link to={linkUrl}>
            <Button>{linkText}</Button>
          </Link>
        </div>
      }
    </div>
  )
}

export default TextWithImage
